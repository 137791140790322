.copyright {
  color: var(--colour-neutral600);
  font-size: 0.8rem;
  margin: 0;
  text-transform: uppercase;
}

.highlight {
  color: var(--colour-yellow500);
}

.wrapper {
  align-items: center;
  background-color: var(--colour-neutral000);
  display: flex;
  flex-direction: column;
  height: 5rem;
  justify-content: space-around;
  width: 100%;
}
