.wrapper {
  background: var(--colour-neutral100);
  border: 1px solid var(--colour-neutral600);
  border-radius: 4px;
  color: var(--colour-neutral600);
  display: inline-flex;
  margin: var(--spacing-small);
  padding: var(--spacing-small);
  transition: all 0.3s;
}

.wrapper svg {
  vertical-align: sub;
}

.wrapper:hover {
  background-color: var(--colour-primary500);
  border-color: var(--colour-primary500);
  color: var(--colour-neutral900);
  cursor: pointer;
}
